import { PetitionTrackingFindResponse } from 'modules/contractedService/application/management/tracking/find/dto/PetitionTrackingFindResponse'
import { ContractedServiceTypeId } from 'modules/contractedService/domain/ContractedServiceType'
import { ContractType } from 'modules/contractedService/domain/management/operation/ContractType'
import { ManagementOperation } from 'modules/contractedService/domain/management/operation/ManagementOperation'
import { BandwidthUnits } from 'ufinet-web-functions'
import { PetitionTrackingStatus } from './PetitionTrackingStatus'

export type PetitionTracking = {
	id: string
	managementOperation: ManagementOperation
	contractType: ContractType
	serviceCode: string
	orsCode?: string
	orsserviceid?: string
	serviceType: ContractedServiceTypeId
	serviceName: string
	serviceId: string
	bandwidth?: {
		id: string
		amount: number
		unit: BandwidthUnits
	}
	commitmentDate: string
	expectedDeliveryDate: string
	endDate: string
	trackingStatus: PetitionTrackingStatus
	creationDate: string
	modificationDate?: string
	isRemovable: boolean
	reversal: boolean
	newServiceId: string
	managementType: string
}

const isPetitionTrackingRemovable = (petitionTrackingFindResponse: PetitionTrackingFindResponse) => {
	const isRemovableTrackingStatus = [PetitionTrackingStatus.PROGRAMADO].includes(
		petitionTrackingFindResponse.trackingStatus
	)
	const isRemovableTrackingType = [ContractType.SCHEDULED, ContractType.TEMPORAL].includes(
		petitionTrackingFindResponse.contractType
	)
	return isRemovableTrackingStatus && isRemovableTrackingType
}

export const petitionTrackingFromResponse: (
	petitionTrackingFindResponse: PetitionTrackingFindResponse
) => PetitionTracking = (dto) => ({
	...dto,
	isRemovable: isPetitionTrackingRemovable(dto),
})
